import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  // {
  //   path: '*',
  //   redirect: '/goods'
  // },
  // {
  //   name: 'user',
  //   component: () => import('./view/user/home'),
  //   meta: {
  //     title: '会员中心'
  //   }
  // },
  {
    path: '/passport/register',
    name: 'register',
    component: () => import('./view/user/register'),
    meta: {
      title: '注册'
    }
  }
  // {
  //   name: 'cart',
  //   component: () => import('./view/cart'),
  //   meta: {
  //     title: '购物车'
  //   }
  // },
  // {
  //   name: 'goods',
  //   component: () => import('./view/goods'),
  //   meta: {
  //     title: '商品详情'
  //   }
  // }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({mode: "history",base:"/", routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

//const routerhistory = new Router({ mode: "history",base:"/",routers:routes});
//export default new Router({ mode: "history",base:"/",routers:routes});

export {
   router
};
